import { For } from 'solid-js';

import type { WatchHistoryResponseDto } from '~/modules/api';

import { CarouselGallery, Tile } from '../shared';
import { Slide } from '../ui';

interface WatchHistoryGalleryProps {
  id: string;
  items: WatchHistoryResponseDto[];
  title: string;
  section: string;
}

export function WatchHistoryGallery(props: WatchHistoryGalleryProps) {
  return (
    <CarouselGallery
      id={props.id}
      heading={{
        title: props.title,
      }}
      hideArrows={props.items.length < 4}
    >
      <For each={props.items}>
        {item =>
          item.content && (
            <Slide class="df-scrollable-list-item-4-col">
              <Tile
                preset="VerticalTileCondensed"
                content={item.content}
                trackingProperties={{ section: props.section }}
              />
            </Slide>
          )
        }
      </For>
    </CarouselGallery>
  );
}
